<template>
    <div class="Apply">
        <div class="group-box" v-if="!next">
            <div class="public-header">
                <div class="title">
                    contribute
                </div>
            </div>
            <!--            <div class="avatar-tit">-->
            <!--                <p>AVATARDAO初始OG成员数量为99名，每个成员可以以50000USDT的价格领取1-3个DAO的凭证NFT。</p>-->
            <!--                <p>有兴趣吗？在下面获得认证。</p>-->
            <!--            </div>-->
            <el-form :label-position="labelPosition" ref="validateForm1" label-width="80px" :model="formLabelAlign">
                <el-form-item label="name"
                              prop="name"
                              :rules="[ { required: true, message: 'Please fill in your name'} ]"
                >
                    <el-input v-model="formLabelAlign.name"
                              placeholder="name"></el-input>
                </el-form-item>
                <el-form-item label="Please introduce yourself, and investment experience"
                              prop="experience"
                              :rules="[ { required: true, message: 'Please introduce yourself, and investment experience'} ]"
                >
                    <el-input v-model="formLabelAlign.experience"
                              placeholder="Please introduce yourself, and investment experience"></el-input>
                </el-form-item>
                <el-form-item label="Company or occupation"
                              prop="profession"
                              :rules="[ { required: true, message: 'Company or occupation'} ]"
                >
                    <el-input v-model="formLabelAlign.profession" placeholder="Company or occupation"></el-input>
                </el-form-item>
                <el-form-item
                        prop="phone"
                        :rules="[ { required: true, message: 'Please fill in your phone'} ]"
                        label="Telephone">
                    <el-input v-model="formLabelAlign.phone" placeholder="Telephone"></el-input>
                </el-form-item>
                <el-form-item
                        prop="Email"
                        :rules="[ {
                            required: true,
                            // message: 'E-mail'
                            trigger: 'blur',
                            validator: validateAddress
                        } ]"
                        label="E-mail">
                    <el-input v-model="formLabelAlign.Email" placeholder="please input your email"></el-input>
                </el-form-item>
                <el-form-item
                        prop="twitter"
                        :rules="[ { required: true, message: 'Please enter twitter account'} ]"
                        label="Twitter handle">
                    <el-input v-model="formLabelAlign.twitter" placeholder="twitter handle"></el-input>
                </el-form-item>
                <el-form-item
                        prop="recommender"
                        :rules="[ { required: true, message: 'Please enter your referees'} ]"
                        label="referees">
                    <el-input v-model="formLabelAlign.recommender" placeholder="referees"></el-input>
                </el-form-item>
                <el-form-item label="Number of NFTs in mint">
                    <el-radio-group v-model="formLabelAlign.nftNum">
                        <el-radio :label="1">1</el-radio>
                        <el-radio :label="2">2</el-radio>
                        <el-radio :label="3">3</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div class="next-btn" v-if="configCom&&configCom.member_join" @click="submitForm('validateForm1')">
                <span>Next Step</span>
                <i class="iconfont icon-jiantou_xiangyou"></i>
            </div>
        </div>
        <div class="group-box" v-else>
            <div class="public-header">
                <div class="title">
                    KYC
                </div>
            </div>
            <el-form :label-position="labelPosition" ref="validateForm2" label-width="80px" :model="identity">
                <el-form-item
                        label="E-mail"
                >
                    <el-input v-model="formLabelAlign.Email" disabled placeholder="E-mail"></el-input>
                </el-form-item>
                <el-form-item
                        prop="birth"
                        :rules="[ { required: false, message: 'birth date'} ]"
                        label="birth date">
                    <el-input v-model="identity.birth" placeholder="birth date"></el-input>
                </el-form-item>
                <el-form-item
                        prop="frontidCard"
                        :rules="[ { required: false, message: 'Frontal scanning of ID card'} ]"
                        label="Frontal scanning of ID card">
                    <div class="upload-item">
                        <span v-if="!identity.frontidCard">Upload files</span>
                        <span class="filename" v-else>{{identity.frontidCard.name}}</span>
                        <input type="file" @change="changeIdCard($event,'frontidCard')"
                               accept="image/png,image/gif,image/jpg,image/jpeg"/>
                    </div>
                </el-form-item>
                <el-form-item
                        prop="backidCard"
                        :rules="[ { required: false, message: 'Back scanning of ID card'} ]"
                        label="Back scanning of ID card">
                    <div class="upload-item">
                        <span v-if="!identity.backidCard">Upload files</span>
                        <span class="filename" v-else>{{identity.backidCard.name}}</span>
                        <input type="file"
                               @change="changeIdCard($event,'backidCard')"
                               accept="image/png,image/gif,image/jpg,image/jpeg"/>
                    </div>
                </el-form-item>
                <el-form-item
                        prop="backidCard"
                        :rules="[ { required: false} ]"
                        label="Organization name (if any)">
                    <el-input v-model="identity.agency" placeholder="Organization name"></el-input>
                </el-form-item>
                <el-form-item
                        prop="idCardNum"
                        :rules="[ { required: false, message: 'Passport or ID card number'} ]"
                        label="Passport or ID card number">
                    <el-input v-model="identity.idCardNum" placeholder="Passport or ID card number"></el-input>
                </el-form-item>
                <el-form-item
                        prop="phone"
                        label="mobile"
                        :rules="[ { required: false, message: 'Please enter your mobile number'} ]"
                >
                    <el-input v-model="identity.phone" placeholder="mobile"></el-input>
                </el-form-item>
            </el-form>
            <div class="btn-wrap">
                <div class="next-btn" v-if="configCom&&configCom.member_join" @click="submitForm('validateForm2')">
                    <span>Submit</span>
                </div>
                <div class="skip-btn" @click="submitForm('validateForm2')">
                    <span>Skip</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    let AWS = require("aws-sdk");
    export default {
        name: "Apply",
        data() {
            return {
                labelPosition: "top",
                next: false,
                s3: new AWS.S3({ // AWS 认证相关
                    accessKeyId: 'AKIAZUCS5FS34O2CZ2PT',
                    secretAccessKey: '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',
                    region: 'ap-east-1'
                }),
                formLabelAlign: {
                    name: "",
                    experience: "",
                    profession: "",
                    phone: "",
                    twitter: "",
                    recommender: "",
                    Email: "",
                    nftNum: 1
                },
                identity: {
                    // email: "",
                    birth: "",
                    frontidCard: "",
                    frontidCardUrl: "",
                    backidCard: "",
                    backidCardUrl: "",
                    agency: "",
                    idCardNum: "",
                    phone: ""
                },
                configCom: null
            }
        },
        mounted() {
            if (this.userInfo.userinfo && this.userInfo.userinfo.status == 1 || this.userInfo.userinfo && this.userInfo.userinfo.status == 0) {
                this.$router.push({name: "Audit"});
            }
            if (this.userInfo) {
                // this.commonConfig();
            }
        },
        methods: {
            validateAddress(rule, value, callback) {
                let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                if (value === '') {
                    callback(new Error('Please enter email address'));
                } else if (!reg.test(value)) {
                    callback(new Error('Please enter the correct link address'));
                } else {
                    callback();
                }
            },
            async commonConfig() {
                let res = await this.api.auth(this.token).commonConfig();
                if (res.data.code == 200) {
                    this.configCom = res.data.data;
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (formName == 'validateForm1') {
                            this.next = true;
                        }
                        if (formName == 'validateForm2') {
                            this.sendForm();
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            changeIdCard(e, name) {
                const file = e.target.files[0];
                this.identity[name] = file;
                this.upLoadFile(file, name);
            },
            upLoadFile(file, name) {
                let key = new Date().getTime() + "_" + Math.random().toFixed(2) + "." + file.name.split(".")[1];
                const params = {
                    Bucket: 'avatar-dao', // 存储桶名称
                    Key: key, // 文件名，重名会覆盖
                    ContentType: file.type, // 文件类型
                    Body: file, // 具体的文件
                    'Access-Control-Allow-Credentials': '*',
                    'ACL': 'public-read'
                };
                let loading = this.$loading({
                    lock: true,
                    text: "upload file...",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.s3.upload(params, {
                    // queueSize: 1 // 并发数
                }).send((err, data) => {
                    if (err) {
                        loading.close();
                        this.$message({
                            message: "upload error",
                            type: "error",
                        })
                    } else {
                        loading.close();
                        this.$message({
                            message: "Uploaded file successfully",
                            type: "success",
                        })
                        this.identity[name + 'Url'] = data.Location;
                    }
                });
            },
            async sendForm() {
                let loading = this.$loading({
                    lock: true,
                    text: "submitting...",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let res = await this.api.auth(this.token).userinfoStore({
                    name: this.formLabelAlign.name,
                    nft_count: this.formLabelAlign.nftNum,
                    introduce: this.formLabelAlign.experience,
                    job: this.formLabelAlign.profession,
                    tele_phone: this.formLabelAlign.phone,
                    twitter: this.formLabelAlign.twitter,
                    recommender: this.formLabelAlign.recommender,
                    email: this.formLabelAlign.Email,
                    birthday: this.identity.birth,
                    id_front_uri: this.identity.frontidCardUrl,
                    id_back_uri: this.identity.backidCardUrl,
                    id_number: this.identity.idCardNum,
                    company: this.identity.agency,
                    phone: this.identity.phone
                });
                if (res.data.code == 200) {
                    loading.close();
                    this.$message({
                        message: "Submitted successfully",
                        type: "success",
                    });
                    this.$router.push({name: "Audit"});
                }
            }
        }
    }
</script>
<style lang="scss">
    .Apply {
        width: 100%;
        min-height: 100vh;
        padding-bottom: 80px;

        .el-input__inner {
            width: 100%;
            height: 60px;
            background-color: #F8F9FD;
            border-radius: 2px;
            border: 1px solid #D6D9E5;
            color: #0F0F0F;
            font-size: 18px;
        }

        .el-input__inner::-webkit-input-placeholder {
            color: #87899D;
        }

        .el-form-item__label {
            color: #0F0F0F;
            font-size: 18px;
            font-weight: 500;
        }
    }
</style>
<style lang="scss" scoped>
    .Apply {
        width: 100%;
        min-height: 100vh;

        .group-box {
            margin: 0 auto;
            width: 820px;
        }

        .avatar-tit {
            margin: 50px 0;
            color: #575757;
            font-size: 14px;

            p {
                margin-bottom: 20px;
            }
        }

        .btn-wrap {
            display: flex;
            align-items: center;
        }

        .next-btn {
            margin-top: 50px;
            width: 129px;
            height: 60px;
            background-color: #3A56FF;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            font-weight: 500;
            cursor: pointer;

            span {
                margin-right: 5px;
                font-size: 18px;
            }
        }

        .skip-btn {
            @extend .next-btn;
            margin-left: 46px;
            color: #3A56FF;
            background-color: #FFFFFF;
            border: 1px solid #3A56FF;
        }

        .upload-item {
            cursor: pointer;
            position: relative;
            width: 100%;
            height: 60px;
            line-height: 60px;
            border-radius: 2px;
            border: 1px solid #D6D9E5;
            background: #F8F9FD;
            font-size: 18px;
            color: #87899D;
            box-sizing: border-box;
            padding: 0 20px;

            input {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 999;
                width: 100%;
                height: 100%;
                display: inline-block;
                opacity: 0;
                cursor: pointer;
            }

            .filename {
                color: #3A56FF;
            }
        }
    }

    @media screen and (max-width: 850px) {
        .Apply .group-box {
            width: 95%;
        }
    }
</style>
