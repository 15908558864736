<template>
    <div class="settlement">
        <div class="container">
            <img src="../../assets/settlement.png" alt="" class="image">
            <el-button type="primary" class="btn">结算收益</el-button>
        </div>
    </div>
</template>

<script>
    import {Init} from "../../plugins/web3";
    import avatar from "../../ABI/avatar.json";

    export default {
        name: "settlement",
        data() {
            return {}
        },
        methods: {
            async settlementVotes() {
                let provider = Init();
                this.web3 = new this.Web3(provider);
                let myContract = await new this.web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                let isVote = await myContract.methods.balanceOf(this.username).call();
                console.log("isVote", isVote);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        margin: 0 auto;
        width: 1200px;
        height: 712px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .image {
            width: 228px;
            height: 202px;
        }

        .btn {
            margin-top: 100px;
            width: 442px;
            height: 54px;
            font-size: 16px;
        }
    }

    @media screen and (max-width: 1200px) {
        .container {
            width: 100%;

            .btn {
                width: 85%;
            }
        }
    }
</style>
